<template>
    <div class="ma-3">
        <v-row dense class="grid">
            <v-col
              v-for="item in items"
              :key="item.link"
              md="4"
            >
                <v-card>
                    <v-card-subtitle class="pb-0">{{ item.date }}</v-card-subtitle>
                    <v-card-title>
                        <a :href="item.link" target="_blank">{{ item.title }}</a>
                    </v-card-title>
                    <v-card-subtitle v-if="item.author">By {{ item.author.name }}</v-card-subtitle>
                    <v-card-text v-html="item.description"></v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import Http from '@/utils/httpClient';
    import Masonry from 'masonry-layout';

    export default {
        data() {
            return {
                items: [],
            }
        },
        watch: {
            $route() {
                this.init();
            }
        },
        mounted() {
            this.init();
        },
        methods: {
            init() {
                this.$root.$loading.open({ text: 'Fetching RSS' });
                Http.get(`rss/${this.$route.params.rssId}`)
                    .then(({ data }) => {
                        this.items = data.items;
                        this.$nextTick(() => {
                            new Masonry('.grid', {
                                itemSelector: "[class*='col']",
                                //columnWidth: 300,
                                //gutter: 10
                            })
                        });
                        this.$root.$loading.close();                        
                    })
                    .catch(() => this.$root.$loading.close());
            }
        }
    }
</script>
